@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'flowbite';
@import 'animate.css';
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/effect-fade';
/* Aeonik Black */
@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/AEONIK-BLACK.woff2') format('woff2'),
    url('/fonts/Aeonik-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/AEONIK-BLACKITALIC.woff2') format('woff2'),
    url('/fonts/Aeonik-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

/* Aeonik Bold */
@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/AEONIK-BOLD.woff2') format('woff2'),
    url('/fonts/Aeonik-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/AEONIK-BOLDITALIC.woff2') format('woff2'),
    url('/fonts/Aeonik-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

/* Aeonik Light */
@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/AEONIK-LIGHT.woff2') format('woff2'),
    url('/fonts/Aeonik-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/AEONIK-LIGHTITALIC.woff2') format('woff2'),
    url('/fonts/Aeonik-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

/* Aeonik Medium */
@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/AEONIK-MEDIUM.woff2') format('woff2'),
    url('/fonts/Aeonik-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/AEONIK-MEDIUMITALIC.woff2') format('woff2'),
    url('/fonts/Aeonik-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

/* Aeonik Regular */
@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/AEONIK-REGULAR.woff2') format('woff2'),
    url('/fonts/Aeonik-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/AEONIK-REGULARITALIC.woff2') format('woff2'),
    url('/fonts/Aeonik-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

/* Aeonik Thin */
@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/AEONIK-THIN.woff2') format('woff2'),
    url('/fonts/Aeonik-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/AEONIK-THINITALIC.woff2') format('woff2'),
    url('/fonts/Aeonik-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

/* Define a fonte padrão para o corpo do documento */
body {
  font-family: 'Aeonik', sans-serif;
}
body {
  font-family: 'Aeonik', sans-serif;
}

@media screen and (min-width: 768px) {
  #hero {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  #hero .logo-container {
    display: flex;
  }
  #middle-content {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}


